import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { FAQS, SITES } from "../common/api";
import Footer from "../components/frames/Footer";
import Header from "../components/frames/Header";
import SiteLink from "../components/SiteLink";
import { setAccessCookie } from "../common/apiManager";
import { useNavigate } from "react-router-dom"

const PROD_LOGIN = "https://kingocoin.cs.skku.edu";
const DEV_LOGIN = "http://kingocoin-dev.cs.skku.edu:8080";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  label: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, label, ...other } = props;

  return (
    <div className="flex items-center justify-center w-full">
      <div hidden={value !== index} {...other} className="w-full">
        {value === index && (
          <div className="flex flex-col w-full gap-4 py-8">
            <h1 className="text-title-m">{label}</h1>
            <hr className="w-8 h-1 ml-2 bg-primary"></hr>
            <div className="font-light font-sans text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">{children}</div>
          </div>
        )}
      </div>
    </div>
  );
}

function Main() {
  const [value, setValue] = useState(0);
  const [buttonText, setButtonText] = useState("킹고ID LOGIN"); // 버튼 텍스트 상태 추가
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // const handleLoginClick = () => {
  //   window.location.href = PROD_LOGIN + "/api/login"; // 로그인 클릭 시 PROD_LOGIN으로 이동
  // };

  const handleLoginClick = async () => {
    const name = "유혜진";
    try {
      const response = await fetch(`${DEV_LOGIN}/api/dev/token/admin/?name=${name}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.text();
        setAccessCookie(data);

        // 토큰을 가지고 원하는 페이지로 리다이렉션
        navigate("/dashboard");
      } else {
        console.error("Failed to fetch token.");
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  };


  return (
    <div className="flex flex-col items-center bg-surface">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          킹고코인 사이트 첫 로그인 시 1000코인 증정!
        </Alert>
      </Snackbar>
      <div className="flex items-center justify-end w-screen h-8 max-w-full p-1 px-4 md:px-8 bg-primary">
        <a href={PROD_LOGIN + `/api/login`} className="text-onPrimary text-label-m">
          {buttonText}
        </a>
      </div>
      {/* <button onClick={handleLoginClick}>
        관리자 로그인
      </button> */}
      <Header />
      <div className="relative flex justify-center mb-8">
        <img
          src={process.env.PUBLIC_URL + "/main/banner.jpg"}
          className="w-full h-auto"
          alt="성균관대학교 삼성정보학술관 전경 사진"
        />
        <p className="absolute select-none h-full w-full flex justify-center items-center bottom-0 right-0 bg-gradient-to-t from-black opacity-80 to-transparent text-center text-white font-bold text-[30px] sm:text-[60px] p-2 sm:p-4">
          K I N G O C O I N
        </p>
      </div>
      <div className="w-full py-8 min-h-screen px-4 sm:px-8">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          className="h-12 md:h-16 shadow-md"
        >
          <Tab
            label="킹고코인이란?"
            className="h-12 md:h-16 shadow-sm font-light text-xxxs xs:text-md sm:text-lg md:text-xl pl-0 pr-0"
          />
          <Tab
            label="코인 적립 방법"
            className="h-12 md:h-16 shadow-sm font-light text-xxxs xs:text-md sm:text-lg md:text-xl pl-0 pr-0"
          />
          <Tab
            label="코인 사용 혜택"
            className="h-12 md:h-16 shadow-sm font-light text-xxxs xs:text-md sm:text-lg md:text-xl pl-0 pr-0"
          />
          <Tab
            label="자주 물어보는 질문"
            className="h-12 md:h-16 shadow-sm font-light text-xxxs xs:text-md sm:text-lg md:text-xl pl-0 pr-0"
          />
        </Tabs>
        <TabPanel value={value} index={0} label="킹고코인이란?">
          성균관대 구성원들의 비교과 활동에 대한 인센티브로서 블록체인 기반으로 개발된 마일리지 플랫폼입니다.
          <br />
          <br />
          킹고코인은 구성원들의 활동을 투명하게 기록하기 위해 퍼블릭 블록체인 플랫폼과 오프 체인 방식을 채택하였습니다.
          이는 활동 내역을 퍼블릭 블록체인에 오프 체인 방식으로 모아서 저장하고,
          데이터의 신뢰성과 보존성을 보장하며 활동 내역을 신뢰성 있게 영구히 보존할 수 있습니다.
          <br />
          <br />
          킹고코인을 통해 성균관대 구성원들은 활동에 대한 인센티브를 받을 수 있을 뿐만 아니라,
          마일리지를 활용하여 다양한 혜택과 교내 서비스를 이용할 수 있게 됩니다.
          이를 통해 학생들의 참여와 활발한 비교과 활동이 장려되고,
          성균관대의 학습 및 커뮤니티 환경을 더욱 발전시키는 것이 킹고코인의 목표입니다.
        </TabPanel>
        <TabPanel value={value} index={1} label="킹고코인을 모으는 방법?">
          킹고코인은 학교내 다양한 비교과 활동에 참여함으로써 모을 수 있습니다.
          킹코코인이 적립되는 다양한 행사들은 아래 각 공지 게시판을 참고해 주시고,
          공지글 본문 내용에 있는 킹고코인 관련 안내를 참고해 주시면 됩니다.
          <div className="flex flex-wrap gap-2 sm:gap-3 mt-8">
            {SITES.map((it) => (
              <SiteLink
                platform={it}
                key={it.pfName}
                className="flex justify-center items-center gap-2 sm:gap-4 w-full sm:w-[calc(50%-0.75rem)] md:w-[calc(33.333%-1rem)] py-2 sm:py-4 text-onBackground bg-background rounded-lg border-solid border-[1px] border-primary hover:shadow-lg hover:scale-[1.03] transition-all"
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} label="킹고코인 사용 혜택?">
          <div>
            <span className="text-sm xs:text-md sm:text-base md:text-xl lg:text-xxl">
              장학금
            </span><br />
            매년 3월 1일부터 다음해 2월 말일까지 누적된 킹고코인 적립 실적에 따라, 상위 적립자 중 10명에게 각 30만원의 장학금이 수여됩니다.
          </div>

          <div className="mt-8">
            <span className="text-sm xs:text-md sm:text-base md:text-xl lg:text-xxl">
              유료 계정 대여
            </span><br />
            코인 차감으로 1주일 단위로 ChatGPT Plus와 Colab Pro 유료 계정을 사용하실 수 있습니다.
          </div>

          <TableContainer component={Paper} className="my-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">계정</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">월 구독료 (학교 계정)</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">코인 차감 (1주일당)</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">신청 기간</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">사용 기간</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">ChatGPT Plus</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">$20</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">4,000코인</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">매주 월~수</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">차주 월~일 단위 사용</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">Colab Pro</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">$9.99</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">3,000코인</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">-</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open('https://forms.gle/qpn2C3C6P2Up51db9', '_blank')}
            className="text-xs sm:text-sm md:text-base lg:text-lg"
          >
            유료계정 대여 신청하기
          </Button>

          <div className="mt-8">
            <span className="text-sm xs:text-md sm:text-base md:text-xl lg:text-xxl">
              IT 장비 대여
            </span><br />
            노트북, 모니터, 맥북 등 장비를 코인 차감으로 대여 사용할 수 있습니다.
          </div>
          <TableContainer component={Paper} className="my-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">장비</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">수량</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">코인 차감</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">신청 기간</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">사용 기간</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">맥북프로16</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">2</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">2,000코인/일</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">수시</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">일 단위, 최대 1일</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">맥북프로</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">1</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">2,000코인/일</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">수시</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">노트북</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">11</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">1,000코인/일</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">수시</TableCell>
                  <TableCell className="text-xxxs xs: text-xxs sm:text-xs md:text-sm lg:text-base xl:text-lg">일 단위, 최대 14일</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open('mailto:cks612@skku.edu')}
            className="text-xs sm:text-sm md:text-base lg:text-lg"
          >
            IT장비 대여 문의하기
          </Button>

          <div className="mt-8">
            <span className="text-sm xs:text-md sm:text-base md:text-xl lg:text-xxl">
              선발 가산점
            </span><br />
            서류/면접 평가를 통한 선발이 필요한 행사 참여 시, 킹고코인 보유 실적에 따라 선발에 도움이 되는 가산점을 얻을 수 있습니다.
            <br />
            (각 행사 공지 참조)
          </div>

          <div className="mt-4">
            ※ 향후 학생식당, 기념품샵 등 학교 내 주요 장소에서 사용 확대될 예정입니다.
          </div>

          <div className="mt-8">
            <strong>*+*+* 킹고코인 오픈 프로모션 안내 *+*+*</strong><br />
            킹고코인 사이트 방문하여 첫 로그인 시 1000코인 증정! (ID당 1회 한정)
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open('https://kingocoin.cs.skku.edu/', '_blank')}
            className="text-xs sm:text-sm md:text-base lg:text-lg"
          >
            킹고코인 로그인하기
          </Button>
        </TabPanel>
        <TabPanel value={value} index={3} label="자주 물어보는 질문">
          {FAQS.map((it) => (
            <Accordion key={it.faqId} className="mb-4 rounded-lg shadow">
              <AccordionSummary
                className="flex flex-row-reverse gap-2 p-2 sm:p-4 md:p-6 rounded-lg"
                expandIcon={<ExpandMore className="w-4 h-4 sm:w-6 h-6" />}
              >
                <div className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                  {it.question}
                </div>
              </AccordionSummary>
              <AccordionDetails className="px-4 sm:px-8 md:px-12">
                <div className="text-xs sm:text-sm md:text-base lg:text-lg font-light">
                  {it.answer}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
