import React, { useState, useEffect, forwardRef } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { getStaticsByMonth, IPageOptions, IStaticsByMonth, getMyStaticsByMonth } from '../../../common/api';
import Status from '../../feedback/Status';
import CustomPagination from '../../../components/CustomPagination';

interface UserChartProps {
  userId: number;
  pageSize: number;
  className?: string;
}

// UserChartCard를 forwardRef로 래핑
const UserChartCard = forwardRef<HTMLDivElement, UserChartProps>(({ userId, pageSize, className }, ref) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [statistics, setStatistics] = useState<IStaticsByMonth[]>([]);
  const [totalRows, setTotalRows] = useState(0);

  const pageOptions: IPageOptions = {
    page: page,
    size: size,
  };

  const fetchChart = async (userId: number, pageOptions: IPageOptions) => {
    // const data = await getStaticsByMonth(userId, pageOptions);
    const data = await getMyStaticsByMonth(pageOptions);

    return data;
  };

  const {
    isLoading: statisticIsLoading,
    error: statisticError,
    refetch
  } = useQuery(["statistic", userId, pageOptions], () => fetchChart(userId, pageOptions), {
    onSuccess: (data) => {
      setStatistics(data.staticsResponses);
      setTotalRows(data.totalCount);
    },
  });

  useEffect(() => {
    refetch();
  }, [page, size, refetch]);

  const render =
    !statisticIsLoading && !statisticError && statistics && statistics.length > 0;

  const columns: GridColDef[] = [
    { field: 'year', headerName: '년', flex: 1 },
    { field: 'month', headerName: '월', flex: 1 },
    { field: 'point', headerName: '합계', flex: 1 },
  ];

  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Card ref={ref} className={className}>
      <CardHeader
        title="누적코인보유량"
        titleTypographyProps={{ variant: 'display' }}
      />
      <CardContent>
        <Status
          isLoading={statisticIsLoading}
          error={statisticError}
          isData={statistics && statistics.length > 0}
          className="h-full w-full" // DataGrid와 동일한 높이
        />
        {render && (
          <DataGrid
            rows={statistics}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: 'year', sort: 'desc' }],
              },
              pagination: {
                paginationModel: {
                  pageSize: size,
                  page: page,
                },
              },
            }}
            pageSizeOptions={[size]}
            rowCount={totalRows}
            paginationMode="server"
            components={{
              Pagination: () => (
                <CustomPagination
                  pageCount={Math.ceil(totalRows / size)}
                  page={page}
                  onPageChange={handlePageChange}
                />
              ),
            }}
            componentsProps={{
              footer: { className: 'border-0 justify-center items-center' },
            }}
          />
        )}
      </CardContent>
    </Card>
  );
});

export default UserChartCard;
