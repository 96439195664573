import { Card, CardContent, CardHeader, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { ICoin, SITES, getCoin, getCoinDetailByStudent, getMyCoin, getMyCoinDetailByStudent } from "../common/api";
import { check, dayjsToStamp, getAccessCookie, checkTokenExp } from "../common/apiManager";
import CounterCard from "../components/Counter";
import SiteLink from "../components/SiteLink";
import UserChartCard from "../components/cards/chart/UserChartCard";
import UserCoinDetailCard from "../components/cards/table/UserCoinDetailCard";
import Status from "../components/feedback/Status";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import RedirectDialog from "../components/RedirectDialog";
import { deleteAllCookies } from "../common/utils";


const Dashboard = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const isSmDown = useMediaQuery('(max-width: 640px)'); // sm 크기 이하의 화면 감지

  const {
    isLoading: loginIsLoading,
    error: loginError,
    data: login,
  } = useQuery("login", check, {
    enabled: true,
  });

  const {
    isLoading: userCoinIsLoading,
    error: userCoinError,
    data: userCoin,
  } = useQuery(["userCoin"], () =>
    getMyCoin(),
    // getCoin(login!.userId),
    {
      enabled: !!login, // login이 존재할 때만 쿼리를 실행
    }
  );

  const {
    isLoading: recentCoinDetailIsLoading,
    error: recentCoinDetailError,
    data: recentCoinDetail,
  } = useQuery(
    ["recentCoinDetail", userCoin?.userId],
    // () => getCoinDetailByStudent(userCoin!.userId, 0, 1),
    () => getMyCoinDetailByStudent(0, 1),
    {
      enabled: !!userCoin, // Only fetch if userCoin exists
    }
  );

  useEffect(() => {
    const accessToken = getAccessCookie();
    console.log("Policies 페이지", accessToken);

    if (!accessToken) { // 토큰이 없는 경우
      navigate('/');
      return;
    }
    if (!checkTokenExp(accessToken)) { // 토큰이 만료된 경우
      deleteAllCookies();
      setIsDialogOpen(true);
      return;
    }
  }, [navigate]);

  const handleConfirm = () => {
    setIsDialogOpen(false);
    navigate('/'); // 다이얼로그에서 확인 버튼을 누르면 로그인 화면으로 리다이렉트
  };

  const isLoading = loginIsLoading || userCoinIsLoading;
  const error = loginError || userCoinError;
  const isData = login && userCoin && recentCoinDetail ? true : false;

  const render = !isLoading && !error && isData;

  const calculateCountSize = (number: number) => {
    const length = number.toString().length;
    if (length == 0) {
      return "text-5xl sm:text-6xl lg:text-7xl";
    } else if (length === 3) {
      return "text-4xl sm:text-5xl lg:text-6xl";
    } else if (length === 4) {
      return "text-3xl sm:text-4xl lg:text-5xl";
    } else if (length >= 5) {
      return "text-2xl sm:text-3xl lg:text-4xl";
    }
  };

  const calculateDeltaSize = (number: number) => {
    const length = number.toString().length;
    if (length == 0) {
      return "text-5xl sm:text-6xl lg:text-7xl";
    } else if (length === 3) {
      return "text-4xl sm:text-5xl lg:text-6xl";
    } else if (length === 4) {
      return "text-3xl sm:text-4xl lg:text-5xl";
    } else if (length >= 5) {
      return "text-2xl sm:text-3xl lg:text-4xl";
    }
  };

  // 가장 큰 숫자의 자릿수에 맞추어 digitSize를 설정
  const maxDigitSize = userCoin ? Math.max(
    userCoin.pointTotal,
    userCoin.pointPlus,
    userCoin.pointMinus
  ) : 0;
  const countSize = calculateCountSize(maxDigitSize);
  // const deltaSize = calculateDeltaSize(recentCoinDetail?.coinDetail[0].point ?? 0);

  // const coinDeltaPlus = recentCoinDetail?.coinDetail[0].plus ?? true;
  // const coinDelta = recentCoinDetail?.coinDetail[0].point ?? 0;

  return (
    <>
      <RedirectDialog
        open={isDialogOpen}
        handleConfirm={handleConfirm}
      >
        토큰이 만료되어 로그아웃되었습니다. 로그인 화면으로 이동합니다.
      </RedirectDialog>
      <Status
        isLoading={isLoading}
        error={error}
        isData={isData}
        className="w-screen h-screen max-w-full"
      />
      {render && (
        <>
          <section className="flex flex-col flex-wrap md:flex-row md:flex-nowrap gap-6 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col gap-6 w-full md:w-1/2">
              <div className="flex flex-nowrap gap-4 sm:gap-6">
                {userCoin && (
                  <>
                    <CounterCard
                      label={"보유한 코인"}
                      target={userCoin.pointTotal}
                      // deltaPlus={coinDeltaPlus}
                      // delta={coinDelta}
                      duration={1000}
                      className="flex-grow flex-basis-0"
                      countSize={countSize}
                    // deltaSize={deltaSize}
                    />
                    <CounterCard
                      label={"획득한 코인"}
                      target={userCoin.pointPlus}
                      duration={1000}
                      className="flex-grow flex-basis-0"
                      countSize={countSize}
                    />
                    <CounterCard
                      label={"사용한 코인"}
                      target={userCoin.pointMinus}
                      duration={1000}
                      className="flex-grow flex-basis-0"
                      countSize={countSize}
                    />
                  </>
                )}
              </div>
              {!isSmDown && (
                <Card className="w-full bg-background flex-grow">
                  <CardHeader
                    title="연관 사이트로 이동"
                    titleTypographyProps={{ variant: "display" }}
                  />
                  <CardContent>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 auto-rows-fr">
                      {SITES.map((it) => (
                        <SiteLink
                          platform={it}
                          key={it.pfName}
                          className="flex justify-center items-center gap-1 text-onBackground bg-background rounded-lg border-solid border-[1px] border-primary hover:shadow-lg hover:scale-[1.03] transition-all h-full"
                        />
                      ))}
                    </div>
                  </CardContent>
                </Card>
              )}

            </div>
            <div className="w-full md:w-1/2">
              <UserChartCard
                userId={login!.userId}
                pageSize={4}
                className="w-full h-full"
              />
            </div>
          </section>
          <section className="flex flex-wrap gap-6 mt-6 px-4 sm:px-6 lg:px-8">
            <UserCoinDetailCard
              userId={login!.userId}
              pageSize={4}
              className="flex-grow w-full"
            />
          </section>
        </>
      )}
    </>
  );
};

export default Dashboard;
